const packs = [
  {
    id: 1,
    title: 'Arabic Alphabet',
    category: 'Fundamentals',
    count: 28,
    description: 'Master the Arabic alphabet with this comprehensive pack. The Arabic script consists of 28 basic letters, written from right to left. Each letter can have up to four different forms depending on its position in a word. This pack covers all letters, their pronunciations, and their various forms, providing a solid foundation for reading and writing Arabic.',
    items: [
      {
        arabic: 'ا',
        transliteration: 'Alif',
        translation: 'A',
        audioUrl: 'https://file-examples.com/storage/fe74e638c366e3f719d5298/2017/11/file_example_MP3_700KB.mp3',
        info: 'Alif is the first letter of the Arabic alphabet. It can represent a glottal stop or a long "a" sound. In its isolated form, it looks like a vertical stroke.'
      },
      {
        arabic: 'ب',
        transliteration: 'Ba',
        translation: 'B',
        audioUrl: 'https://example.com/audio/ba.mp3',
        info: 'Ba is the second letter of the Arabic alphabet. It makes a "b" sound similar to English. It has a single dot below the main body of the letter.'
      },
      {
        arabic: 'ت',
        transliteration: 'Ta',
        translation: 'T',
        audioUrl: 'https://example.com/audio/ta.mp3',
        info: 'Ta is the third letter of the Arabic alphabet. It makes a "t" sound similar to English. It has two dots above the main body of the letter.'
      },
      {
        arabic: 'ث',
        transliteration: 'Tha',
        translation: 'Th',
        audioUrl: 'https://example.com/audio/tha.mp3',
        info: 'Tha makes a sound similar to the "th" in "think". It has three dots above the main body of the letter.'
      },
      {
        arabic: 'ج',
        transliteration: 'Jim',
        translation: 'J',
        audioUrl: 'https://example.com/audio/jim.mp3',
        info: 'Jim makes a "j" sound as in "jam". It has one dot below the main body of the letter.'
      },
      {
        arabic: 'ح',
        transliteration: 'Ha',
        translation: 'H',
        audioUrl: 'https://example.com/audio/ha.mp3',
        info: 'Ha is a heavy "h" sound made from the throat. It has no dots and looks like a loop.'
      },
      {
        arabic: 'خ',
        transliteration: 'Kha',
        translation: 'Kh',
        audioUrl: 'https://example.com/audio/kha.mp3',
        info: 'Kha makes a sound similar to clearing your throat. It looks like Ha but with a dot above.'
      },
      {
        arabic: 'د',
        transliteration: 'Dal',
        translation: 'D',
        audioUrl: 'https://example.com/audio/dal.mp3',
        info: 'Dal makes a "d" sound similar to English. It has no dots and looks like a backwards comma.'
      },
      {
        arabic: 'ذ',
        transliteration: 'Dhal',
        translation: 'Dh',
        audioUrl: 'https://example.com/audio/dhal.mp3',
        info: 'Dhal makes a sound similar to the "th" in "this". It looks like Dal but with a dot above.'
      },
      {
        arabic: 'ر',
        transliteration: 'Ra',
        translation: 'R',
        audioUrl: 'https://example.com/audio/ra.mp3',
        info: 'Ra makes a rolled "r" sound. It has no dots and looks like a small wave.'
      },
      {
        arabic: 'ز',
        transliteration: 'Zay',
        translation: 'Z',
        audioUrl: 'https://example.com/audio/zay.mp3',
        info: 'Zay makes a "z" sound similar to English. It looks like Ra but with a dot above.'
      },
      {
        arabic: 'س',
        transliteration: 'Sin',
        translation: 'S',
        audioUrl: 'https://example.com/audio/sin.mp3',
        info: 'Sin makes an "s" sound similar to English. It has no dots and looks like a wide "w".'
      },
      {
        arabic: 'ش',
        transliteration: 'Shin',
        translation: 'Sh',
        audioUrl: 'https://example.com/audio/shin.mp3',
        info: 'Shin makes a "sh" sound as in "ship". It looks like Sin but with three dots above.'
      },
      {
        arabic: 'ص',
        transliteration: 'Sad',
        translation: 'S (emphatic)',
        audioUrl: 'https://example.com/audio/sad.mp3',
        info: 'Sad is an emphatic "s" sound. It has no dots and looks like a loop with a small tail.'
      },
      {
        arabic: 'ض',
        transliteration: 'Dad',
        translation: 'D (emphatic)',
        audioUrl: 'https://example.com/audio/dad.mp3',
        info: 'Dad is an emphatic "d" sound. It looks like Sad but with a dot above.'
      },
      {
        arabic: 'ط',
        transliteration: 'Ta',
        translation: 'T (emphatic)',
        audioUrl: 'https://example.com/audio/ta_emphatic.mp3',
        info: 'Ta is an emphatic "t" sound. It has no dots and looks like a loop with a long tail.'
      },
      {
        arabic: 'ظ',
        transliteration: 'Dha',
        translation: 'Dh (emphatic)',
        audioUrl: 'https://example.com/audio/dha.mp3',
        info: 'Dha is an emphatic "th" sound. It looks like Ta but with a dot above.'
      },
      {
        arabic: 'ع',
        transliteration: 'Ayn',
        translation: '(voiced pharyngeal)',
        audioUrl: 'https://example.com/audio/ayn.mp3',
        info: 'Ayn is a unique Arabic sound made deep in the throat. It has no English equivalent.'
      },
      {
        arabic: 'غ',
        transliteration: 'Ghayn',
        translation: 'Gh',
        audioUrl: 'https://example.com/audio/ghayn.mp3',
        info: 'Ghayn makes a sound similar to the French "r". It looks like Ayn but with a dot above.'
      },
      {
        arabic: 'ف',
        transliteration: 'Fa',
        translation: 'F',
        audioUrl: 'https://example.com/audio/fa.mp3',
        info: 'Fa makes an "f" sound similar to English. It has one dot above the main body of the letter.'
      },
      {
        arabic: 'ق',
        transliteration: 'Qaf',
        translation: 'Q',
        audioUrl: 'https://example.com/audio/qaf.mp3',
        info: 'Qaf makes a deep "k" sound from the back of the throat. It has two dots above the main body.'
      },
      {
        arabic: 'ك',
        transliteration: 'Kaf',
        translation: 'K',
        audioUrl: 'https://example.com/audio/kaf.mp3',
        info: 'Kaf makes a "k" sound similar to English. It has no dots and looks like a sideways "7".'
      },
      {
        arabic: 'ل',
        transliteration: 'Lam',
        translation: 'L',
        audioUrl: 'https://example.com/audio/lam.mp3',
        info: 'Lam makes an "l" sound similar to English. It has no dots and looks like a hook.'
      },
      {
        arabic: 'م',
        transliteration: 'Mim',
        translation: 'M',
        audioUrl: 'https://example.com/audio/mim.mp3',
        info: 'Mim makes an "m" sound similar to English. It has no dots and looks like a small circle.'
      },
      {
        arabic: 'ن',
        transliteration: 'Nun',
        translation: 'N',
        audioUrl: 'https://example.com/audio/nun.mp3',
        info: 'Nun makes an "n" sound similar to English. It has one dot above the main body.'
      },
      {
        arabic: 'ه',
        transliteration: 'Ha',
        translation: 'H',
        audioUrl: 'https://example.com/audio/ha_soft.mp3',
        info: 'Ha makes a soft "h" sound. It has no dots and looks like a small loop in its isolated form.'
      },
      {
        arabic: 'و',
        transliteration: 'Waw',
        translation: 'W',
        audioUrl: 'https://example.com/audio/waw.mp3',
        info: 'Waw makes a "w" sound and can also represent the long "u" vowel. It has no dots.'
      },
      {
        arabic: 'ي',
        transliteration: 'Ya',
        translation: 'Y',
        audioUrl: 'https://example.com/audio/ya.mp3',
        info: 'Ya makes a "y" sound and can also represent the long "i" vowel. It has two dots below the main body.'
      }
    ]
  },
  {
    id: 2,
    title: 'Kuwaiti Greetings ',
    category: 'Conversation',
    count: 20,
    description: 'Master common Kuwaiti and Gulf Arabic greetings and phrases. This pack includes both formal and informal greetings used in Kuwait and the Gulf region, helping you navigate social situations with ease.',
    items: [
      {
        arabic: 'مرحبا',
        transliteration: 'Marhaba',
        translation: 'Hello',
        audioUrl: 'https://example.com/audio/marhaba.mp3',
        info: 'A common, friendly greeting used throughout the Arab world, including Kuwait.'
      },
      {
        arabic: 'السلام عليكم',
        transliteration: 'As-salaam-alaikum',
        translation: 'Peace be upon you',
        audioUrl: 'https://example.com/audio/assalaamualaikum.mp3',
        info: 'The most common Islamic greeting, widely used in Kuwait and other Arab countries.'
      },
      {
        arabic: 'شلونك',
        transliteration: 'Shlonak',
        translation: 'How are you? (to a male)',
        audioUrl: 'https://example.com/audio/shlonak.mp3',
        info: 'A casual way to ask "How are you?" in Kuwaiti dialect, used when addressing a male.'
      },
      {
        arabic: 'شلونج',
        transliteration: 'Shlonik',
        translation: 'How are you? (to a female)',
        audioUrl: 'https://example.com/audio/shlonik.mp3',
        info: 'A casual way to ask "How are you?" in Kuwaiti dialect, used when addressing a female.'
      },
      {
        arabic: 'هلا والله',
        transliteration: 'Hala walla',
        translation: 'Hello (informal)',
        audioUrl: 'https://example.com/audio/halawalla.mp3',
        info: 'An informal, warm greeting commonly used among friends in Kuwait.'
      },
      {
        arabic: 'صباح الخير',
        transliteration: 'Sabah al-khair',
        translation: 'Good morning',
        audioUrl: 'https://example.com/audio/sabahalkhair.mp3',
        info: 'A common morning greeting in Kuwait and other Arab countries.'
      },
      {
        arabic: 'مساء الخير',
        transliteration: 'Masa al-khair',
        translation: 'Good evening',
        audioUrl: 'https://example.com/audio/masaalkhair.mp3',
        info: 'A common evening greeting in Kuwait and other Arab countries.'
      },
      {
        arabic: 'تشرفنا',
        transliteration: 'Tasharrafna',
        translation: 'Nice to meet you',
        audioUrl: 'https://example.com/audio/tasharrafna.mp3',
        info: 'A polite phrase used when meeting someone for the first time in Kuwait.'
      },
      {
        arabic: 'مع السلامة',
        transliteration: 'Ma\'a as-salama',
        translation: 'Goodbye',
        audioUrl: 'https://example.com/audio/maassalama.mp3',
        info: 'A common farewell phrase in Kuwait and other Arab countries.'
      },
      {
        arabic: 'إلى اللقاء',
        transliteration: 'Ila al-liqa',
        translation: 'Until we meet again',
        audioUrl: 'https://example.com/audio/ilaalliqa.mp3',
        info: 'Another farewell phrase, often used in more formal situations.'
      },
      {
        arabic: 'تصبح على خير',
        transliteration: 'Tusbih ala khair',
        translation: 'Good night',
        audioUrl: 'https://example.com/audio/tusbihalakhair.mp3',
        info: 'A phrase used to wish someone a good night before going to sleep.'
      },
      {
        arabic: 'يعطيك العافية',
        transliteration: 'Ya\'tik al-afiya',
        translation: 'May God give you health',
        audioUrl: 'https://example.com/audio/yatikalafiya.mp3',
        info: 'A common phrase used to express gratitude or to say "thank you" in Kuwaiti dialect.'
      },
      {
        arabic: 'بالتوفيق',
        transliteration: 'Bit-tawfeeq',
        translation: 'Good luck',
        audioUrl: 'https://example.com/audio/bittawfeeq.mp3',
        info: 'A phrase used to wish someone good luck or success in Kuwait.'
      },
      {
        arabic: 'ما شاء الله',
        transliteration: 'Mashallah',
        translation: 'God has willed it',
        audioUrl: 'https://example.com/audio/mashallah.mp3',
        info: 'An expression of appreciation or praise, often used to compliment someone in Kuwait.'
      },
      {
        arabic: 'إن شاء الله',
        transliteration: 'Inshallah',
        translation: 'God willing',
        audioUrl: 'https://example.com/audio/inshallah.mp3',
        info: 'A very common phrase in Kuwait, used when talking about future plans or events.'
      },
      {
        arabic: 'شخبارك',
        transliteration: 'Shakhbarak',
        translation: 'How are you? (informal)',
        audioUrl: 'https://example.com/audio/shakhbarak.mp3',
        info: 'A very common informal way to ask "How are you?" in Kuwaiti and Gulf dialects.'
      },
      {
        arabic: 'الله يسلمك',
        transliteration: 'Allah yisalmak',
        translation: 'May God protect you',
        audioUrl: 'https://example.com/audio/allahyisalmak.mp3',
        info: 'A common response to greetings or thanks in Kuwait and the Gulf.'
      },
      {
        arabic: 'فمان الله',
        transliteration: 'Fi aman Allah',
        translation: 'In God\'s safety (goodbye)',
        audioUrl: 'https://example.com/audio/fiamanallah.mp3',
        info: 'A farewell phrase commonly used in Kuwait and other Gulf countries.'
      },
      {
        arabic: 'يا هلا',
        transliteration: 'Ya hala',
        translation: 'Welcome',
        audioUrl: 'https://example.com/audio/yahala.mp3',
        info: 'An enthusiastic welcome greeting commonly used in Kuwait and the Gulf.'
      },
      {
        arabic: 'حياك الله',
        transliteration: 'Hayak Allah',
        translation: 'May God greet you (welcome)',
        audioUrl: 'https://example.com/audio/hayakallah.mp3',
        info: 'A warm welcome phrase used in Kuwait and other Gulf countries.'
      }
    ]
  },
  {
    id: 3,
    title: 'Kuwaiti Family Terms ',
    category: 'Vocabulary',
    count: 15,
    description: 'Learn common Kuwaiti Arabic terms for family members and relatives. This pack covers both immediate family and extended family terms used in Kuwait.',
    items: [
      {
        arabic: 'أبوي',
        transliteration: 'Abuy',
        translation: 'My father',
        audioUrl: 'https://example.com/audio/abuy.mp3',
        info: 'The Kuwaiti term for "my father", used informally.'
      },
      {
        arabic: 'أمي',
        transliteration: 'Umi',
        translation: 'My mother',
        audioUrl: 'https://example.com/audio/umi.mp3',
        info: 'The Kuwaiti term for "my mother", used informally.'
      },
      {
        arabic: 'أخوي',
        transliteration: 'Akhuy',
        translation: 'My brother',
        audioUrl: 'https://example.com/audio/akhuy.mp3',
        info: 'The Kuwaiti term for "my brother".'
      },
      {
        arabic: 'أختي',
        transliteration: 'Ukhti',
        translation: 'My sister',
        audioUrl: 'https://example.com/audio/ukhti.mp3',
        info: 'The Kuwaiti term for "my sister".'
      },
      {
        arabic: 'يدي',
        transliteration: 'Yiddi',
        translation: 'My grandfather',
        audioUrl: 'https://example.com/audio/yiddi.mp3',
        info: 'The Kuwaiti term for "my grandfather".'
      },
      {
        arabic: 'يدتي',
        transliteration: 'Yidditi',
        translation: 'My grandmother',
        audioUrl: 'https://example.com/audio/yidditi.mp3',
        info: 'The Kuwaiti term for "my grandmother".'
      },
      {
        arabic: 'عمي',
        transliteration: 'Ammi',
        translation: 'My paternal uncle',
        audioUrl: 'https://example.com/audio/ammi.mp3',
        info: 'The Kuwaiti term for "my paternal uncle".'
      },
      {
        arabic: 'خالي',
        transliteration: 'Khali',
        translation: 'My maternal uncle',
        audioUrl: 'https://example.com/audio/khali.mp3',
        info: 'The Kuwaiti term for "my maternal uncle".'
      },
      {
        arabic: 'عمتي',
        transliteration: 'Ammiti',
        translation: 'My paternal aunt',
        audioUrl: 'https://example.com/audio/ammiti.mp3',
        info: 'The Kuwaiti term for "my paternal aunt".'
      },
      {
        arabic: 'خالتي',
        transliteration: 'Khalti',
        translation: 'My maternal aunt',
        audioUrl: 'https://example.com/audio/khalti.mp3',
        info: 'The Kuwaiti term for "my maternal aunt".'
      },
      {
        arabic: 'ولد عمي',
        transliteration: 'Wild ammi',
        translation: 'My paternal cousin (male)',
        audioUrl: 'https://example.com/audio/wildammi.mp3',
        info: 'The Kuwaiti term for "my male paternal cousin".'
      },
      {
        arabic: 'بنت عمي',
        transliteration: 'Bint ammi',
        translation: 'My paternal cousin (female)',
        audioUrl: 'https://example.com/audio/bintammi.mp3',
        info: 'The Kuwaiti term for "my female paternal cousin".'
      },
      {
        arabic: 'ولد خالي',
        transliteration: 'Wild khali',
        translation: 'My maternal cousin (male)',
        audioUrl: 'https://example.com/audio/wildkhali.mp3',
        info: 'The Kuwaiti term for "my male maternal cousin".'
      },
      {
        arabic: 'بنت خالي',
        transliteration: 'Bint khali',
        translation: 'My maternal cousin (female)',
        audioUrl: 'https://example.com/audio/bintkhali.mp3',
        info: 'The Kuwaiti term for "my female maternal cousin".'
      },
      {
        arabic: 'حرمتي',
        transliteration: 'Hurmiti',
        translation: 'My wife',
        audioUrl: 'https://example.com/audio/hurmiti.mp3',
        info: 'The Kuwaiti term for "my wife", used informally.'
      }
    ]
  },
  {
    id: 4,
    title: 'Kuwaiti Food and Drink ',
    category: 'Vocabulary',
    count: 15,
    description: 'Explore common Kuwaiti and Gulf Arabic terms for food and drinks. This pack covers traditional Kuwaiti dishes, beverages, and related phrases.',
    items: [
      {
        arabic: 'مچبوس',
        transliteration: 'Machboos',
        translation: 'Spiced rice with meat',
        audioUrl: 'https://example.com/audio/machboos.mp3',
        info: 'A popular Kuwaiti dish made with rice, meat, and spices.'
      },
      {
        arabic: 'مطبق',
        transliteration: 'Mutabbaq',
        translation: 'Layered meat pie',
        audioUrl: 'https://example.com/audio/mutabbaq.mp3',
        info: 'A traditional Kuwaiti pastry filled with meat and spices.'
      },
      {
        arabic: 'هريس',
        transliteration: 'Harees',
        translation: 'Wheat and meat porridge',
        audioUrl: 'https://example.com/audio/harees.mp3',
        info: 'A popular Gulf dish made from wheat and meat, often served during Ramadan.'
      },
      {
        arabic: 'قهوة عربية',
        transliteration: 'Qahwa Arabiya',
        translation: 'Arabic coffee',
        audioUrl: 'https://example.com/audio/qahwaarabiya.mp3',
        info: 'Traditional Arabic coffee, often served with dates in Kuwait.'
      },
      {
        arabic: 'شاي',
        transliteration: 'Chai',
        translation: 'Tea',
        audioUrl: 'https://example.com/audio/chai.mp3',
        info: 'Tea is a popular beverage in Kuwait, often served with milk and sugar.'
      },
      {
        arabic: 'لقيمات',
        transliteration: 'Luqaimat',
        translation: 'Sweet dumplings',
        audioUrl: 'https://example.com/audio/luqaimat.mp3',
        info: 'Sweet, crispy dumplings drizzled with sugar syrup, popular in Kuwait and the Gulf.'
      },
      {
        arabic: 'بلالیط',
        transliteration: 'Balaleet',
        translation: 'Sweet vermicelli and eggs',
        audioUrl: 'https://example.com/audio/balaleet.mp3',
        info: 'A Kuwaiti breakfast dish made with sweet vermicelli noodles and eggs.'
      },
      {
        arabic: 'شنينة',
        transliteration: 'Shneena',
        translation: 'Buttermilk drink',
        audioUrl: 'https://example.com/audio/shneena.mp3',
        info: 'A popular Kuwaiti drink made from yogurt and water, often flavored with mint.'
      },
      {
        arabic: 'زعفران',
        transliteration: 'Za\'afaran',
        translation: 'Saffron',
        audioUrl: 'https://example.com/audio/zaafaran.mp3',
        info: 'A popular spice used in many Kuwaiti dishes and desserts.'
      },
      {
        arabic: 'تمر',
        transliteration: 'Tamr',
        translation: 'Dates',
        audioUrl: 'https://example.com/audio/tamr.mp3',
        info: 'Dates are a staple in Kuwaiti cuisine and are often served with Arabic coffee.'
      },
      {
        arabic: 'مرق',
        transliteration: 'Maraq',
        translation: 'Broth or stew',
        audioUrl: 'https://example.com/audio/maraq.mp3',
        info: 'A term used for various types of broths or stews in Kuwaiti cuisine.'
        },
            {
        arabic: 'سمك مطبوخ',
        transliteration: 'Samak matboukh',
        translation: 'Cooked fish',
        audioUrl: 'https://example.com/audio/samakmatboukh.mp3',
        info: 'Fish is a common ingredient in Kuwaiti cuisine due to its coastal location.'
      },
      {
        arabic: 'حمص',
        transliteration: 'Hummus',
        translation: 'Chickpea dip',
        audioUrl: 'https://example.com/audio/hummus.mp3',
        info: 'A popular Middle Eastern dip made from chickpeas, also common in Kuwait.'
      },
      {
        arabic: 'فلافل',
        transliteration: 'Falafel',
        translation: 'Fried chickpea balls',
        audioUrl: 'https://example.com/audio/falafel.mp3',
        info: 'A popular street food in Kuwait, often served in pita bread.'
      },
      {
        arabic: 'شاورما',
        transliteration: 'Shawarma',
        translation: 'Meat sandwich',
        audioUrl: 'https://example.com/audio/shawarma.mp3',
        info: 'A popular street food in Kuwait, made with spit-roasted meat in pita bread.'
      }
    ]
    },

  {
    id: 5,
    title: 'Kuwaiti Numbers and Counting ',
    category: 'Vocabulary',
    count: 20,
    description: 'Master Kuwaiti Arabic numbers and counting. This pack covers cardinal and ordinal numbers, as well as common phrases involving numbers.',
    items: [
      {
        arabic: 'واحد',
        transliteration: 'Wahid',
        translation: 'One',
        audioUrl: 'https://example.com/audio/wahid.mp3',
        info: 'The Kuwaiti Arabic word for "one".'
      },
      {
        arabic: 'اثنين',
        transliteration: 'Ithnain',
        translation: 'Two',
        audioUrl: 'https://example.com/audio/ithnain.mp3',
        info: 'The Kuwaiti Arabic word for "two".'
      },
      {
        arabic: 'ثلاثة',
        transliteration: 'Thalatha',
        translation: 'Three',
        audioUrl: 'https://example.com/audio/thalatha.mp3',
        info: 'The Kuwaiti Arabic word for "three".'
      },
      {
        arabic: 'أربعة',
        transliteration: 'Arba\'a',
        translation: 'Four',
        audioUrl: 'https://example.com/audio/arbaa.mp3',
        info: 'The Kuwaiti Arabic word for "four".'
      },
      {
        arabic: 'خمسة',
        transliteration: 'Khamsa',
        translation: 'Five',
        audioUrl: 'https://example.com/audio/khamsa.mp3',
        info: 'The Kuwaiti Arabic word for "five".'
      },
      {
        arabic: 'ستة',
        transliteration: 'Sitta',
        translation: 'Six',
        audioUrl: 'https://example.com/audio/sitta.mp3',
        info: 'The Kuwaiti Arabic word for "six".'
      },
      {
        arabic: 'سبعة',
        transliteration: 'Sab\'a',
        translation: 'Seven',
        audioUrl: 'https://example.com/audio/saba.mp3',
        info: 'The Kuwaiti Arabic word for "seven".'
      },
      {
        arabic: 'ثمانية',
        transliteration: 'Thamaniya',
        translation: 'Eight',
        audioUrl: 'https://example.com/audio/thamaniya.mp3',
        info: 'The Kuwaiti Arabic word for "eight".'
      },
      {
        arabic: 'تسعة',
        transliteration: 'Tis\'a',
        translation: 'Nine',
        audioUrl: 'https://example.com/audio/tisa.mp3',
        info: 'The Kuwaiti Arabic word for "nine".'
      },
      {
        arabic: 'عشرة',
        transliteration: 'Ashra',
        translation: 'Ten',
        audioUrl: 'https://example.com/audio/ashra.mp3',
        info: 'The Kuwaiti Arabic word for "ten".'
      },
      {
        arabic: 'عشرين',
        transliteration: 'Ishreen',
        translation: 'Twenty',
        audioUrl: 'https://example.com/audio/ishreen.mp3',
        info: 'The Kuwaiti Arabic word for "twenty".'
      },
      {
        arabic: 'خمسين',
        transliteration: 'Khamseen',
        translation: 'Fifty',
        audioUrl: 'https://example.com/audio/khamseen.mp3',
        info: 'The Kuwaiti Arabic word for "fifty".'
      },
      {
        arabic: 'مية',
        transliteration: 'Miya',
        translation: 'Hundred',
        audioUrl: 'https://example.com/audio/miya.mp3',
        info: 'The Kuwaiti Arabic word for "hundred".'
      },
      {
        arabic: 'ألف',
        transliteration: 'Alf',
        translation: 'Thousand',
        audioUrl: 'https://example.com/audio/alf.mp3',
        info: 'The Kuwaiti Arabic word for "thousand".'
      },
      {
        arabic: 'مليون',
        transliteration: 'Malyoon',
        translation: 'Million',
        audioUrl: 'https://example.com/audio/malyoon.mp3',
        info: 'The Kuwaiti Arabic word for "million".'
      },
      {
        arabic: 'الأول',
        transliteration: 'Al-awwal',
        translation: 'First',
        audioUrl: 'https://example.com/audio/alawwal.mp3',
        info: 'The Kuwaiti Arabic word for "first" (ordinal number).'
      },
      {
        arabic: 'الثاني',
        transliteration: 'Ath-thani',
        translation: 'Second',
        audioUrl: 'https://example.com/audio/aththani.mp3',
        info: 'The Kuwaiti Arabic word for "second" (ordinal number).'
      },
      {
        arabic: 'الثالث',
        transliteration: 'Ath-thalith',
        translation: 'Third',
        audioUrl: 'https://example.com/audio/aththalith.mp3',
        info: 'The Kuwaiti Arabic word for "third" (ordinal number).'
      },
      {
        arabic: 'كم؟',
        transliteration: 'Kam?',
        translation: 'How many?',
        audioUrl: 'https://example.com/audio/kam.mp3',
        info: 'Used to ask "how many?" or "how much?" in Kuwaiti Arabic.'
      },
      {
        arabic: 'نص',
        transliteration: 'Nus',
        translation: 'Half',
        audioUrl: 'https://example.com/audio/nus.mp3',
        info: 'The Kuwaiti Arabic word for "half", often used in prices and measurements.'
      }
    ]
  },
  {
    id: 6,
    title: 'Kuwaiti Weather and Seasons ',
    category: 'Vocabulary',
    count: 15,
    description: 'Learn about weather conditions and seasons in Kuwaiti Arabic. This pack covers common weather terms, seasonal expressions, and related phrases.',
    items: [
      {
        arabic: 'حار',
        transliteration: 'Haar',
        translation: 'Hot',
        audioUrl: 'https://example.com/audio/haar.mp3',
        info: 'A common term to describe the hot weather in Kuwait.'
      },
      {
        arabic: 'بارد',
        transliteration: 'Baarid',
        translation: 'Cold',
        audioUrl: 'https://example.com/audio/baarid.mp3',
        info: 'Used to describe cold weather, although it\'s less common in Kuwait.'
      },
      {
        arabic: 'مشمس',
        transliteration: 'Mushms',
        translation: 'Sunny',
        audioUrl: 'https://example.com/audio/mushms.mp3',
        info: 'Describes sunny weather, which is very common in Kuwait.'
      },
      {
        arabic: 'ممطر',
        transliteration: 'Mumtir',
        translation: 'Rainy',
        audioUrl: 'https://example.com/audio/mumtir.mp3',
        info: 'Describes rainy weather, which is less frequent in Kuwait.'
      },
      {
        arabic: 'رياح',
        transliteration: 'Riyaah',
        translation: 'Winds',
        audioUrl: 'https://example.com/audio/riyaah.mp3',
        info: 'Refers to winds, which can be strong in Kuwait, especially during sandstorms.'
      },
      {
        arabic: 'عاصفة رملية',
        transliteration: 'Aasifa ramliya',
        translation: 'Sandstorm',
        audioUrl: 'https://example.com/audio/aasifaramliya.mp3',
        info: 'A common weather phenomenon in Kuwait, especially during certain seasons.'
      },
      {
        arabic: 'رطب',
        transliteration: 'Ratib',
        translation: 'Humid',
        audioUrl: 'https://example.com/audio/ratib.mp3',
        info: 'Describes humid weather, which is common in Kuwait due to its coastal location.'
      },
      {
        arabic: 'الصيف',
        transliteration: 'As-sayf',
        translation: 'Summer',
        audioUrl: 'https://example.com/audio/assayf.mp3',
        info: 'The summer season in Kuwait, known for its extremely hot temperatures.'
      },
      {
        arabic: 'الشتاء',
        transliteration: 'Ash-shita',
        translation: 'Winter',
        audioUrl: 'https://example.com/audio/ashshita.mp3',
        info: 'The winter season in Kuwait, which is relatively mild compared to other regions.'
      },
      {
        arabic: 'الربيع',
        transliteration: 'Ar-rabi',
        translation: 'Spring',
        audioUrl: 'https://example.com/audio/arrabi.mp3',
        info: 'The spring season in Kuwait, often brief and mild.'
      },
      {
        arabic: 'الخريف',
        transliteration: 'Al-kharif',
        translation: 'Autumn',
        audioUrl: 'https://example.com/audio/alkharif.mp3',
        info: 'The autumn season in Kuwait, which is less distinct than in other climates.'
      },
      {
        arabic: 'درجة الحرارة',
        transliteration: 'Darajat al-harara',
        translation: 'Temperature',
        audioUrl: 'https://example.com/audio/darajatalharara.mp3',
        info: 'Used when discussing or asking about the temperature.'
      },
      {
        arabic: 'غائم',
        transliteration: 'Gha\'im',
        translation: 'Cloudy',
        audioUrl: 'https://example.com/audio/ghaim.mp3',
        info: 'Describes cloudy weather, which is less common in Kuwait.'
      },
      {
        arabic: 'جو لطيف',
        transliteration: 'Jaw latif',
        translation: 'Nice weather',
        audioUrl: 'https://example.com/audio/jawlatif.mp3',
        info: 'A phrase used to describe pleasant weather conditions.'
      },
      {
        arabic: 'موجة حر',
        transliteration: 'Mawjat har',
        translation: 'Heat wave',
        audioUrl: 'https://example.com/audio/mawjathar.mp3',
        info: 'Refers to periods of extremely hot weather, common in Kuwaiti summers.'
      }
    ]
  },
  {
    id: 7,
    title: 'Kuwaiti Clothing and Fashion ',
    category: 'Vocabulary',
    count: 15,
    description: 'Explore traditional and modern Kuwaiti clothing terms. This pack covers names for various garments, accessories, and fashion-related phrases.',
    items: [
      {
        arabic: 'دشداشة',
        transliteration: 'Dishdasha',
        translation: 'Traditional men\'s robe',
        audioUrl: 'https://example.com/audio/dishdasha.mp3',
        info: 'The traditional long robe worn by Kuwaiti men.'
      },
      {
        arabic: 'عباية',
        transliteration: 'Abaya',
        translation: 'Women\'s cloak',
        audioUrl: 'https://example.com/audio/abaya.mp3',
        info: 'A loose over-garment worn by some women in Kuwait.'
      },
      {
        arabic: 'غترة',
        transliteration: 'Ghutra',
        translation: 'Men\'s headdress',
        audioUrl: 'https://example.com/audio/ghutra.mp3',
        info: 'The traditional men\'s headdress in Kuwait, usually white.'
      },
      {
        arabic: 'عقال',
        transliteration: 'Iqal',
        translation: 'Men\'s headband',
        audioUrl: 'https://example.com/audio/iqal.mp3',
        info: 'The black cord worn by men to keep the ghutra in place.'
      },
      {
        arabic: 'بشت',
        transliteration: 'Bisht',
        translation: 'Men\'s cloak',
        audioUrl: 'https://example.com/audio/bisht.mp3',
        info: 'A traditional men\'s cloak worn over the dishdasha on special occasions.'
      },
      {
        arabic: 'جلابية',
        transliteration: 'Jalabeya',
        translation: 'Long dress',
        audioUrl: 'https://example.com/audio/jalabeya.mp3',
        info: 'A traditional long dress worn by some women in Kuwait.'
      },
      {
        arabic: 'نقاب',
        transliteration: 'Niqab',
        translation: 'Face veil',
        audioUrl: 'https://example.com/audio/niqab.mp3',
        info: 'A face veil worn by some women in Kuwait.'
      },
      {
        arabic: 'حجاب',
        transliteration: 'Hijab',
        translation: 'Headscarf',
        audioUrl: 'https://example.com/audio/hijab.mp3',
        info: 'A headscarf worn by many Muslim women in Kuwait.'
      },
      {
        arabic: 'ثوب',
        transliteration: 'Thobe',
        translation: 'Traditional garment',
        audioUrl: 'https://example.com/audio/thobe.mp3',
        info: 'Another term for the traditional long robe worn in Kuwait.'
      },
      {
        arabic: 'بدلة',
        transliteration: 'Badla',
        translation: 'Suit',
        audioUrl: 'https://example.com/audio/badla.mp3',
        info: 'A Western-style suit, worn in formal business settings.'
      },
      {
        arabic: 'فستان',
        transliteration: 'Fustan',
        translation: 'Dress',
        audioUrl: 'https://example.com/audio/fustan.mp3',
        info: 'A general term for a dress in Kuwaiti Arabic.'
      },
      {
        arabic: 'حذاء',
        transliteration: 'Hitha',
        translation: 'Shoes',
        audioUrl: 'https://example.com/audio/hitha.mp3',
        info: 'The general term for shoes in Kuwaiti Arabic.'
      },
      {
        arabic: 'نظارات شمسية',
        transliteration: 'Nadharaat shamsiya',
        translation: 'Sunglasses',
        audioUrl: 'https://example.com/audio/nadharaatshamsiya.mp3',
        info: 'Sunglasses, essential in the bright Kuwaiti sun.'
      },
      {
        arabic: 'ساعة',
        transliteration: 'Sa\'a',
        translation: 'Watch',
        audioUrl: 'https://example.com/audio/saa.mp3',
        info: 'A wristwatch, often worn as both a functional item and a fashion accessory.'
      },
      {
        arabic: 'خاتم',
        transliteration: 'Khatim',
        translation: 'Ring',
        audioUrl: 'https://example.com/audio/khatim.mp3',
        info: 'A ring, worn as jewelry or to signify marriage.'
      }
    ]
  },
  {
    id: 8,
    title: 'Kuwaiti Transportation and Directions ',
    category: 'Vocabulary',
    count: 20,
    description: 'Learn essential vocabulary for getting around in Kuwait. This pack covers terms related to transportation, directions, and common landmarks.',
    items: [
      {
        arabic: 'سيارة',
        transliteration: 'Sayyara',
        translation: 'Car',
        audioUrl: 'https://example.com/audio/sayyara.mp3',
        info: 'The most common mode of transportation in Kuwait.'
      },
      {
        arabic: 'باص',
        transliteration: 'Baas',
        translation: 'Bus',
        audioUrl: 'https://example.com/audio/baas.mp3',
        info: 'Public buses are available in Kuwait, though less commonly used than cars.'
      },
      {
        arabic: 'تاكسي',
        transliteration: 'Taxi',
        translation: 'Taxi',
        audioUrl: 'https://example.com/audio/taxi.mp3',
        info: 'Taxis are widely available in Kuwait.'
      },
      {
        arabic: 'مطار',
        transliteration: 'Mataar',
        translation: 'Airport',
        audioUrl: 'https://example.com/audio/mataar.mp3',
        info: 'Kuwait International Airport is the main airport in the country.'
      },
      {
        arabic: 'شارع',
        transliteration: 'Shaari\'',
        translation: 'Street',
        audioUrl: 'https://example.com/audio/shaari.mp3',
        info: 'A common term used when giving or asking for directions in Kuwait.'
      },
      {
        arabic: 'دوار',
        transliteration: 'Dawwar',
        translation: 'Roundabout',
        audioUrl: 'https://example.com/audio/dawwar.mp3',
        info: 'Roundabouts are common in Kuwait and often used as landmarks for directions.'
      },
      {
        arabic: 'يمين',
        transliteration: 'Yameen',
        translation: 'Right',
        audioUrl: 'https://example.com/audio/yameen.mp3',
        info: 'Used when giving directions to turn right.'
      },
      {
        arabic: 'يسار',
        transliteration: 'Yasaar',
        translation: 'Left',
        audioUrl: 'https://example.com/audio/yasaar.mp3',
        info: 'Used when giving directions to turn left.'
      },
      {
        arabic: 'مستقيم',
        transliteration: 'Mustaqeem',
        translation: 'Straight',
        audioUrl: 'https://example.com/audio/mustaqeem.mp3',
        info: 'Used when giving directions to go straight ahead.'
      },
      {
        arabic: 'محطة بنزين',
        transliteration: 'Mahattat benzeen',
        translation: 'Gas station',
        audioUrl: 'https://example.com/audio/mahattabenzeen.mp3',
        info: 'Gas stations are common landmarks in Kuwait.'
      },
      {
        arabic: 'موقف سيارات',
        transliteration: 'Mawqif sayyarat',
        translation: 'Parking lot',
        audioUrl: 'https://example.com/audio/mawqifsayyarat.mp3',
        info: 'Parking areas are important to know about in car-centric Kuwait.'
      },
      {
        arabic: 'مجمع',
        transliteration: 'Mujamma\'',
        translation: 'Shopping mall',
        audioUrl: 'https://example.com/audio/mujamma.mp3',
        info: 'Shopping malls are popular destinations and landmarks in Kuwait.'
      },
      {
        arabic: 'مسجد',
        transliteration: 'Masjid',
        translation: 'Mosque',
        audioUrl: 'https://example.com/audio/masjid.mp3',
        info: 'Mosques are common landmarks used for navigation in Kuwait.'
      },
      {
        arabic: 'فندق',
        transliteration: 'Funduq',
        translation: 'Hotel',
        audioUrl: 'https://example.com/audio/funduq.mp3',
        info: 'Hotels are often used as landmarks in Kuwait.'
      },
      {
        arabic: 'سفارة',
        transliteration: 'Sifara',
        translation: 'Embassy',
        audioUrl: 'https://example.com/audio/sifara.mp3',
        info: 'Embassies are important landmarks, especially for expatriates in Kuwait.'
      },
      {
        arabic: 'مستشفى',
        transliteration: 'Mustashfa',
        translation: 'Hospital',
        audioUrl: 'https://example.com/audio/mustashfa.mp3',
        info: 'Hospitals are crucial landmarks to know in case of emergencies.'
      },
      {
        arabic: 'محطة مترو',
        transliteration: 'Mahattat metro',
        translation: 'Metro station',
        audioUrl: 'https://example.com/audio/mahattatmetro.mp3',
        info: 'While Kuwait doesn\'t currently have a metro, this term may be useful in the future or when traveling to other Gulf countries.'
      },
      {
        arabic: 'ميناء',
        transliteration: 'Mina\'',
        translation: 'Port',
        audioUrl: 'https://example.com/audio/mina.mp3',
        info: 'Kuwait has several important ports that serve as landmarks.'
      },
      {
        arabic: 'جسر',
        transliteration: 'Jisr',
        translation: 'Bridge',
        audioUrl: 'https://example.com/audio/jisr.mp3',
        info: 'Bridges are important landmarks in Kuwait, especially those connecting to the islands.'
      },
      {
        arabic: 'وين؟',
        transliteration: 'Wain?',
        translation: 'Where?',
        audioUrl: 'https://example.com/audio/wain.mp3',
        info: 'A common way to ask "where?" in Kuwaiti dialect when asking for directions.'
      }
    ]
  },
  {
    id: 9,
    title: 'Kuwaiti Workplace and Business ',
    category: 'Vocabulary',
    count: 20,
    description: 'Master essential Kuwaiti Arabic terms for the workplace and business interactions. This pack covers job titles, business phrases, and office vocabulary.',
    items: [
      {
        arabic: 'شركة',
        transliteration: 'Sharika',
        translation: 'Company',
        audioUrl: 'https://example.com/audio/sharika.mp3',
        info: 'The general term for a company or corporation in Kuwait.'
      },
      {
        arabic: 'مدير',
        transliteration: 'Mudeer',
        translation: 'Manager',
        audioUrl: 'https://example.com/audio/mudeer.mp3',
        info: 'A common job title in Kuwaiti workplaces.'
      },
      {
        arabic: 'موظف',
        transliteration: 'Muwazzaf',
        translation: 'Employee',
        audioUrl: 'https://example.com/audio/muwazzaf.mp3',
        info: 'The general term for an employee in Kuwait.'
      },
      {
        arabic: 'اجتماع',
        transliteration: 'Ijtimaa\'',
        translation: 'Meeting',
        audioUrl: 'https://example.com/audio/ijtimaa.mp3',
        info: 'A common term used in Kuwaiti workplaces for a meeting or conference.'
      },
      {
        arabic: 'عقد',
        transliteration: 'Aqd',
        translation: 'Contract',
        audioUrl: 'https://example.com/audio/aqd.mp3',
        info: 'An important term in Kuwaiti business, referring to a contract or agreement.'
      },
      {
        arabic: 'راتب',
        transliteration: 'Ratib',
        translation: 'Salary',
        audioUrl: 'https://example.com/audio/ratib.mp3',
        info: 'The term for salary or wages in Kuwaiti Arabic.'
      },
      {
        arabic: 'مكتب',
        transliteration: 'Maktab',
        translation: 'Office',
        audioUrl: 'https://example.com/audio/maktab.mp3',
        info: 'The word for office in Kuwaiti Arabic.'
      },
      {
        arabic: 'زبون',
        transliteration: 'Zaboon',
        translation: 'Customer',
        audioUrl: 'https://example.com/audio/zaboon.mp3',
        info: 'The term for customer or client in Kuwaiti Arabic.'
      },
      {
        arabic: 'فاتورة',
        transliteration: 'Fatoora',
        translation: 'Invoice',
        audioUrl: 'https://example.com/audio/fatoora.mp3',
        info: 'The word for invoice or bill in Kuwaiti Arabic.'
      },
      {
        arabic: 'تقرير',
        transliteration: 'Taqreer',
        translation: 'Report',
        audioUrl: 'https://example.com/audio/taqreer.mp3',
        info: 'The term for a report in Kuwaiti business contexts.'
      },
      {
        arabic: 'مشروع',
        transliteration: 'Mashroo\'',
        translation: 'Project',
        audioUrl: 'https://example.com/audio/mashroo.mp3',
        info: 'The word for project in Kuwaiti Arabic.'
      },
      {
        arabic: 'مهمة',
        transliteration: 'Muhimma',
        translation: 'Task',
        audioUrl: 'https://example.com/audio/muhimma.mp3',
        info: 'The term for a task or assignment in Kuwaiti workplaces.'
      },
      {
        arabic: 'إجازة',
        transliteration: 'Ijaza',
        translation: 'Vacation',
        audioUrl: 'https://example.com/audio/ijaza.mp3',
        info: 'The word for vacation or leave in Kuwaiti Arabic.'
      },
      {
        arabic: 'ترقية',
        transliteration: 'Tarqiya',
        translation: 'Promotion',
        audioUrl: 'https://example.com/audio/tarqiya.mp3',
        info: 'The term for a job promotion in Kuwaiti Arabic.'
      },
      {
        arabic: 'استقالة',
        transliteration: 'Istiqala',
        translation: 'Resignation',
        audioUrl: 'https://example.com/audio/istiqala.mp3',
        info: 'The word for resignation in Kuwaiti workplaces.'
      },
      {
        arabic: 'مقابلة',
        transliteration: 'Muqabala',
        translation: 'Interview',
        audioUrl: 'https://example.com/audio/muqabala.mp3',
        info: 'The term for an interview, often used in job contexts.'
      },
      {
        arabic: 'خبرة',
        transliteration: 'Khibra',
        translation: 'Experience',
        audioUrl: 'https://example.com/audio/khibra.mp3',
        info: 'The word for experience, often used in professional contexts.'
      },
      {
        arabic: 'مهارة',
        transliteration: 'Mahara',
        translation: 'Skill',
        audioUrl: 'https://example.com/audio/mahara.mp3',
        info: 'The term for skill or expertise in Kuwaiti Arabic.'
      },
      {
        arabic: 'سيرة ذاتية',
        transliteration: 'Seera thatiya',
        translation: 'CV/Resume',
        audioUrl: 'https://example.com/audio/seerathatiya.mp3',
        info: 'The phrase for curriculum vitae or resume in Kuwaiti Arabic.'
      },
      {
        arabic: 'دوام',
        transliteration: 'Dawam',
        translation: 'Work hours',
        audioUrl: 'https://example.com/audio/dawam.mp3',
        info: 'The term for work hours or shift in Kuwaiti workplaces.'
      }
    ]
    },
    {
    id: 10,
    title: 'Kuwaiti Culture and Traditions ',
    category: 'Vocabulary',
    count: 20,
    description: 'Explore Kuwaiti culture and traditions with this vocabulary pack. Learn about important cultural concepts, traditional practices, and common expressions related to Kuwaiti heritage.',
    items: [
      {
        arabic: 'ديوانية',
        transliteration: 'Diwaniya',
        translation: 'Traditional gathering place',
        audioUrl: 'https://example.com/audio/diwaniya.mp3',
        info: 'A traditional Kuwaiti gathering place for men to socialize and discuss various topics.'
      },
      {
        arabic: 'قهوة عربية',
        transliteration: 'Qahwa Arabiya',
        translation: 'Arabic coffee',
        audioUrl: 'https://example.com/audio/qahwaarabiya.mp3',
        info: 'Traditional Arabic coffee, an important part of Kuwaiti hospitality.'
      },
      {
        arabic: 'هلا فبراير',
        transliteration: 'Hala Febrayer',
        translation: 'February Festival',
        audioUrl: 'https://example.com/audio/halafebrayer.mp3',
        info: 'A month-long cultural festival celebrating Kuwait\'s liberation and national day.'
      },
      {
        arabic: 'سدو',
        transliteration: 'Sadu',
        translation: 'Traditional weaving',
        audioUrl: 'https://example.com/audio/sadu.mp3',
        info: 'A traditional Bedouin weaving technique, an important part of Kuwaiti heritage.'
      },
      {
        arabic: 'غوص',
        transliteration: 'Ghaws',
        translation: 'Pearl diving',
        audioUrl: 'https://example.com/audio/ghaws.mp3',
        info: 'Traditional pearl diving, once a major industry in Kuwait\'s pre-oil era.'
      },
      {
        arabic: 'عيد الفطر',
        transliteration: 'Eid Al-Fitr',
        translation: 'Festival of Breaking the Fast',
        audioUrl: 'https://example.com/audio/eidalfitr.mp3',
        info: 'An important Islamic holiday celebrated at the end of Ramadan.'
      },
      {
        arabic: 'جريش',
        transliteration: 'Jereesh',
        translation: 'Traditional wheat dish',
        audioUrl: 'https://example.com/audio/jereesh.mp3',
        info: 'A traditional Kuwaiti dish made from crushed wheat and meat.'
      },
      {
        arabic: 'بخور',
        transliteration: 'Bukhoor',
        translation: 'Incense',
        audioUrl: 'https://example.com/audio/bukhoor.mp3',
        info: 'Fragrant wood chips burned as incense in Kuwaiti homes and gatherings.'
      },
      {
        arabic: 'غبقة',
        transliteration: 'Ghabga',
        translation: 'Late night meal during Ramadan',
        audioUrl: 'https://example.com/audio/ghabga.mp3',
        info: 'A social gathering with a late-night meal during Ramadan, specific to Gulf countries.'
      },
      {
        arabic: 'يوم الاستقلال',
        transliteration: 'Yawm Al-Istiqlal',
        translation: 'Independence Day',
        audioUrl: 'https://example.com/audio/yawmalistiqlal.mp3',
        info: 'Kuwait\'s Independence Day, celebrated on February 25th.'
      },
      {
        arabic: 'مباركة',
        transliteration: 'Mubaraka',
        translation: 'Congratulations/Blessings',
        audioUrl: 'https://example.com/audio/mubaraka.mp3',
        info: 'A common expression used to congratulate or bless someone in Kuwaiti Arabic.'
      },
      {
        arabic: 'فريج',
        transliteration: 'Fareej',
        translation: 'Neighborhood',
        audioUrl: 'https://example.com/audio/fareej.mp3',
        info: 'A traditional Kuwaiti neighborhood or district, often with close-knit communities.'
      },
      {
        arabic: 'صندوق الزواج',
        transliteration: 'Sandooq Al-Zawaj',
        translation: 'Marriage Fund',
        audioUrl: 'https://example.com/audio/sandooqalzawaj.mp3',
        info: 'A government initiative to encourage marriage among Kuwaiti citizens.'
      },
      {
        arabic: 'عرضة',
        transliteration: 'Ardha',
        translation: 'Traditional dance',
        audioUrl: 'https://example.com/audio/ardha.mp3',
        info: 'A traditional Kuwaiti dance performed at celebrations and national events.'
      },
      {
        arabic: 'حق الليلة',
        transliteration: 'Haq Al-Laila',
        translation: 'Children\'s festival',
        audioUrl: 'https://example.com/audio/haqallaila.mp3',
        info: 'A traditional children\'s festival celebrated in the middle of Sha\'ban in the Islamic calendar.'
      },
      {
        arabic: 'قرقيعان',
        transliteration: 'Gargee\'an',
        translation: 'Mid-Ramadan children\'s festival',
        audioUrl: 'https://example.com/audio/gargeean.mp3',
        info: 'A children\'s festival celebrated in the middle of Ramadan in Kuwait and other Gulf countries.'
      },
      {
        arabic: 'مجلس الأمة',
        transliteration: 'Majlis Al-Umma',
        translation: 'National Assembly',
        audioUrl: 'https://example.com/audio/majlisalumma.mp3',
        info: 'Kuwait\'s parliament, an important part of the country\'s political system.'
      },
      {
        arabic: 'سوق المباركية',
        transliteration: 'Souq Al-Mubarakiya',
        translation: 'Mubarakiya Market',
        audioUrl: 'https://example.com/audio/souqalmubarakiya.mp3',
        info: 'A historic market in Kuwait City, known for traditional goods and local cuisine.'
      },
      {
        arabic: 'أبراج الكويت',
        transliteration: 'Abraj Al-Kuwait',
        translation: 'Kuwait Towers',
        audioUrl: 'https://example.com/audio/abrajalkuwait.mp3',
        info: 'Iconic landmarks of Kuwait, symbolizing the country\'s economic renaissance.'
      },
      {
        arabic: 'الله يعطيك العافية',
        transliteration: 'Allah ya\'teek al-\'afiya',
        translation: 'May God give you health',
        audioUrl: 'https://example.com/audio/allahyateekalafiya.mp3',
        info: 'A common Kuwaiti expression used to show appreciation or say thank you.'
      }
    ]
  }
];

export default packs;