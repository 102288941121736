import React, { useState, useRef, useCallback, useEffect } from 'react';
import { View, Text, StyleSheet, Dimensions, TouchableOpacity, Animated, Image, useWindowDimensions, Alert } from 'react-native';
import Carousel from 'react-native-snap-carousel';
import FlipCard from 'react-native-flip-card';
import { Ionicons } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import colors from '../theme/colors';
import { logEvent } from '../utils/firebase';

const { height, width } = Dimensions.get('window');

// Import the custom tap icon
const tapIcon = require('../../assets/tap-icon.png');

const ONBOARDING_SHOWN_KEY = 'onboardingShown';

const LearningScreen = ({ route }) => {
  const { pack } = route.params;
  const [activeIndex, setActiveIndex] = useState(0);
  const [flippedCards, setFlippedCards] = useState({});
  const [showOnboarding, setShowOnboarding] = useState(false);
  const fadeAnim = useRef(new Animated.Value(1)).current;
  const bounceAnim = useRef(new Animated.Value(0)).current;
  const carouselRef = useRef(null);

  const { width: screenWidth } = useWindowDimensions();

  useEffect(() => {
    logEvent('screen_view', { screen_name: 'Learning', pack_id: pack.id, pack_title: pack.title });
    checkOnboardingStatus();
  }, [pack]);

  const checkOnboardingStatus = async () => {
    try {
      const onboardingShown = await AsyncStorage.getItem(ONBOARDING_SHOWN_KEY);
      if (onboardingShown !== 'true') {
        setShowOnboarding(true);
      }
    } catch (error) {
      console.error('Error checking onboarding status:', error);
    }
  };

  useEffect(() => {
    if (showOnboarding) {
      Animated.loop(
        Animated.sequence([
          Animated.timing(bounceAnim, {
            toValue: 1,
            duration: 1000,
            useNativeDriver: true,
          }),
          Animated.timing(bounceAnim, {
            toValue: 0,
            duration: 1000,
            useNativeDriver: true,
          }),
        ])
      ).start();
    }
  }, [showOnboarding]);

  const toggleFlip = useCallback((cardIndex) => {
    logEvent('card_flipped', { pack_id: pack.id, card_index: cardIndex });
    setFlippedCards(prev => ({
      ...prev,
      [cardIndex]: !prev[cardIndex]
    }));
  }, [pack.id]);

  const handlePreviousCard = useCallback(() => {
    logEvent('previous_card', { pack_id: pack.id, current_card_index: activeIndex });
    if (carouselRef.current) {
      const previousIndex = (activeIndex - 1 + pack.items.length) % pack.items.length;
      carouselRef.current.snapToItem(previousIndex);
    }
  }, [activeIndex, pack.id, pack.items.length]);

  const handleNextCard = useCallback(() => {
    logEvent('next_card', { pack_id: pack.id, current_card_index: activeIndex });
    if (carouselRef.current) {
      const nextIndex = (activeIndex + 1) % pack.items.length;
      carouselRef.current.snapToItem(nextIndex);
    }
  }, [activeIndex, pack.id, pack.items.length]);

  const handleOnboardingPress = async () => {
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 500,
      useNativeDriver: true,
    }).start(async () => {
      setShowOnboarding(false);
      try {
        await AsyncStorage.setItem(ONBOARDING_SHOWN_KEY, 'true');
      } catch (error) {
        console.error('Error saving onboarding status:', error);
      }
    });
  };

  const bounceInterpolation = bounceAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 20],
  });

  const handleSnapToItem = useCallback((index) => {
    logEvent('card_changed', { pack_id: pack.id, card_index: index });
    setActiveIndex(index);
  }, [pack.id]);

  const renderCard = useCallback(({ item, index }) => (
    <FlipCard
      flipHorizontal={true}
      flipVertical={false}
      flip={flippedCards[index] || false}
      clickable={true}
      onFlipEnd={(isFlipped) => {
        // This is now only used for logging, not for setting state
        logEvent('card_flip_end', { pack_id: pack.id, card_index: index, is_flipped: isFlipped });
      }}
    >
      {/* Face Side */}
      <TouchableOpacity 
        activeOpacity={1} 
        style={[styles.card, screenWidth > 900 && styles.wideCard]}
        onPress={() => toggleFlip(index)}
      >
        <Text style={styles.cardText}>{item.arabic}</Text>
        <Text style={styles.cardSubText}>{item.transliteration}</Text>
        <Text style={styles.cardSubText}>{item.translation}</Text>
      </TouchableOpacity>
      {/* Back Side */}
      <TouchableOpacity 
        activeOpacity={1} 
        style={[styles.card, screenWidth > 900 && styles.wideCard]}
        onPress={() => toggleFlip(index)}
      >
        <Text style={styles.cardInfoText}>{item.info}</Text>
      </TouchableOpacity>
    </FlipCard>
  ), [flippedCards, screenWidth, toggleFlip, pack.id]);

  return (
    <View style={styles.container}>
      <Carousel
        ref={carouselRef}
        data={pack.items}
        renderItem={renderCard}
        sliderWidth={screenWidth}
        itemWidth={screenWidth - 60}
        onSnapToItem={handleSnapToItem}
        firstItem={activeIndex}
        inactiveSlideScale={0.9}
        inactiveSlideOpacity={0.7}
        loop={true}
          useScrollView={false}
        enableMomentum={true}
        decelerationRate={0.9}
        enableSnap={true}
        snapToAlignment="center"
      />
      <View style={styles.buttonContainer}>
        <TouchableOpacity
          activeOpacity={0.8}
          style={styles.button}
          onPress={handlePreviousCard}
        >
          <Ionicons name="arrow-back-outline" size={24} color="#fff" />
          <Text style={styles.buttonText}>Previous</Text>
        </TouchableOpacity>
        <TouchableOpacity
          activeOpacity={0.8}
          style={styles.button}
          onPress={handleNextCard}
        >
          <Text style={styles.buttonText}>Next</Text>
          <Ionicons name="arrow-forward-outline" size={24} color="#fff" />
        </TouchableOpacity>
      </View>
      {showOnboarding && (
        <Animated.View style={[styles.onboardingOverlay, { opacity: fadeAnim }]}>
          <TouchableOpacity style={styles.onboardingContent} onPress={handleOnboardingPress}>
            <Animated.Image 
              source={tapIcon} 
              style={[
                styles.tapIcon,
                {
                  transform: [{ translateY: bounceInterpolation }]
                }
              ]} 
              resizeMode="contain"
            />
            <Text style={styles.onboardingText}>Tap the card to flip it!</Text>
          </TouchableOpacity>
        </Animated.View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.secondary,
    paddingBottom: 20,
  },
  card: {
    height: height * 0.6,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: colors.secondary,
    justifyContent: 'center',
    backgroundColor: colors.primary,
    marginTop: 20,
    padding: 20,
  },
  wideCard: {
    width: 400,
    alignSelf: 'center',
  },
  cardText: {
    fontFamily: 'AlHurraBold',
    textAlign: 'center',
    fontSize: 60,
    lineHeight: 100,
    backgroundColor: 'transparent',
  },
  cardSubText: {
    textAlign: 'center',
    fontSize: 20,
    fontFamily: 'KhebratMusamim',
    backgroundColor: 'transparent',
    marginTop: 10,
  },
  cardInfoText: {
    textAlign: 'center',
    fontSize: 24,
    backgroundColor: 'transparent',
    color: colors.secondary,
    fontFamily: 'KhebratMusamim',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: 20,
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.primary,
    padding: 10,
    borderRadius: 5,
    width: 120,
  },
  buttonText: {
    color: '#fff',
    marginLeft: 5,
  },
  onboardingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  onboardingContent: {
    // backgroundColor: 'rgba(203, 172, 149, 0.9)',
    padding: 20,
    borderRadius: 10,
    alignItems: 'center',
  },
  onboardingText: {
    color: '#fff',
    fontSize: 18,
    marginTop: 20,
    textAlign: 'center',
  },
  tapIcon: {
    width: 50,
    height: 50,
    tintColor: '#fff',
  },
});

export default LearningScreen;

