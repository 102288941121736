import React from 'react';
import { TouchableOpacity, Image, View, StyleSheet } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import { Ionicons } from '@expo/vector-icons';
import HomeScreen from '../screens/HomeScreen';
import PackDetailScreen from '../screens/PackDetailScreen';
import LearningScreen from '../screens/LearningScreen';
import colors from '../theme/colors';

const Stack = createStackNavigator();

const LogoTitle = () => (
  <Image
    style={styles.logo}
    source={require('../../assets/dark-logo.png')}
    resizeMode="contain"
  />
);

const AppNavigator = () => {
  return (
    <Stack.Navigator
      initialRouteName="Home"
      screenOptions={({ navigation }) => ({
        headerShown: true,
        headerTitle: () => <LogoTitle />,
        headerTitleAlign: 'center',
        headerLeft: () => (
          navigation.canGoBack() ? (
            <TouchableOpacity activeOpacity={0.8} onPress={() => navigation.goBack()} style={styles.backButton}>
              <Ionicons name="arrow-back" size={24} color={colors.primary} />
            </TouchableOpacity>
          ) : null
        ),
        headerStyle: {
          backgroundColor: colors.secondary,
          height: 100, // Adjust this value to match your logo's height
        },
        headerTintColor: colors.primary,
      })}
    >
      <Stack.Screen 
        name="Home" 
        component={HomeScreen} 
        options={{ headerShown: false }}
      />
      <Stack.Screen name="PackDetail" component={PackDetailScreen} />
      <Stack.Screen name="Learning" component={LearningScreen} />
    </Stack.Navigator>
  );
};

const styles = StyleSheet.create({
  logo: {
    width: 150, // Adjust this value based on your logo's dimensions
    height: 150, // Adjust this value based on your logo's dimensions
  },
  backButton: {
    marginLeft: 16,
  },
});

export default AppNavigator;