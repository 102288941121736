import React, { useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, SafeAreaView, Dimensions } from 'react-native';
import HeroSection from '../components/HeroSection';
import colors from '../theme/colors';
import { logEvent } from '../utils/firebase';

const { width: screenWidth } = Dimensions.get('window');

const PackDetailScreen = ({ route, navigation }) => {
  const { pack } = route.params;

  useEffect(() => {
    logEvent('screen_view', { screen_name: 'PackDetail', pack_id: pack.id, pack_title: pack.title });
  }, [pack]);

  const handleStartLearning = () => {
    logEvent('start_learning', { pack_id: pack.id, pack_title: pack.title });
    navigation.navigate('Learning', { pack });
  };

  const containerStyle = [
    styles.container,
    screenWidth > 900 && styles.wideContainer
  ];

  const descriptionStyle = [
    styles.description,
    screenWidth > 900 && styles.wideContent
  ];

  return (
    <SafeAreaView style={containerStyle}>
      <View style={styles.content}>
        <HeroSection title={pack.title} category={pack.category} count={pack.count} />
        <Text style={descriptionStyle}>{pack.description}</Text>
      </View>
      <TouchableOpacity activeOpacity={0.8} style={styles.button} onPress={handleStartLearning}>
        <Text style={styles.buttonText}>Start Learning</Text>
      </TouchableOpacity>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  wideContainer: {
    alignItems: 'center',
  },
  content: {
    flex: 1,
    width: '100%',
  },
  wideContent: {
    fontSize: 20,
    lineHeight: 36,
    maxWidth: 700,
    alignSelf: 'center',
  },
  description: {
    fontFamily: 'KhebratMusamim',
    fontSize: 16,
    padding: 20,
    lineHeight: 24,
  },
  button: {
    backgroundColor: colors.primary,
    padding: 15,
    borderRadius: 8,
    margin: 20,
    alignItems: 'center',
  },
  buttonText: {
    fontFamily: 'KhebratMusamim',
    color: '#fff',
    fontSize: 18,
    fontWeight: 'bold',
  },
});

export default PackDetailScreen;