import './global';
import React, { useState, useEffect } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import AppNavigator from './src/navigation/AppNavigator';
import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import SplashScreen from './src/screens/SplashScreen';
import OnboardingScreen from './src/screens/OnboardingScreen';
import useFonts from './src/utils/useFonts';

const ONBOARDING_COMPLETE_KEY = 'onboardingComplete';

const App = () => {
  const [isLoading, setIsLoading] = useState(Platform.OS !== 'web');
  const [showOnboarding, setShowOnboarding] = useState(false);
  const fontsLoaded = useFonts();

  useEffect(() => {
    checkOnboardingStatus();
    initializeFirebase();
  }, []);

  const checkOnboardingStatus = async () => {
    try {
      const onboardingComplete = await AsyncStorage.getItem(ONBOARDING_COMPLETE_KEY);
      setShowOnboarding(onboardingComplete !== 'true');
      if (Platform.OS === 'web') {
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error checking onboarding status:', error);
      if (Platform.OS === 'web') {
        setIsLoading(false);
      }
    }
  };

  const initializeFirebase = () => {
    if (Platform.OS === 'web') {
      import('./src/utils/firebase.web').then((firebase) => {
        console.log('Firebase initialized for web');
      });
    } else {
      import('./src/utils/firebase').then((firebase) => {
        console.log('Firebase initialized for mobile');
      });
    }
  };

  const handleSplashFinish = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000); // 3000 milliseconds = 3 seconds
  };

  const handleOnboardingFinish = async () => {
    try {
      await AsyncStorage.setItem(ONBOARDING_COMPLETE_KEY, 'true');
      setShowOnboarding(false);
    } catch (error) {
      console.error('Error saving onboarding status:', error);
    }
  };

  if (isLoading && Platform.OS !== 'web') {
    return <SplashScreen onFinish={handleSplashFinish} />;
  }

  if (showOnboarding) {
    return <OnboardingScreen onFinish={handleOnboardingFinish} />;
  }

  return (
    <NavigationContainer>
      <AppNavigator />
    </NavigationContainer>
  );
};

export default App;