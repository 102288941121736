const colors = {
  primary: '#cbac95',
  secondary: '#f2eee7',
  text: {
    primary: '#cbac95',
    secondary: '#f2eee7',
  },
  background: {
    primary: '#f2eee7',
    secondary: '#ffffff',
  },
    accent: '#a88c76',
    black: '#000000',
    white: '#ffffff',
};

export default colors;