import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image } from 'react-native';
import colors from '../theme/colors';

const OnboardingScreen = ({ onFinish }) => {
  return (
    <View style={styles.container}>
      <Image
        source={require('../../assets/dark-logo.png')}
        style={styles.logo}
        resizeMode="contain"
      />
      <Text style={styles.title}>Welcome to KuwaitiWords</Text>
      <Text style={styles.description}>
        Learn Kuwaiti Arabic with ease through flashcards.
      </Text>
      <TouchableOpacity activeOpacity={0.8} style={styles.button} onPress={onFinish}>
        <Text style={styles.buttonText}>Get Started</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.secondary,
    padding: 20,
  },
  logo: {
    width: 300,
      height: 300,
      marginBottom: -100,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    color: colors.primary,
    marginBottom: 20,
    textAlign: 'center',
  },
  description: {
    fontSize: 16,
    color: colors.primary,
    textAlign: 'center',
    marginBottom: 30,
  },
  button: {
    backgroundColor: colors.primary,
    paddingHorizontal: 30,
    paddingVertical: 15,
    borderRadius: 25,
  },
  buttonText: {
    color: colors.secondary,
    fontSize: 18,
    fontWeight: 'bold',
  },
});

export default OnboardingScreen;